import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '',
    component: () => import(/* webpackChunkName: "about" */ '../views/index/home.vue'),
    children: [
      {
        path: '/',
        name: 'index',
        component: () => import(/* webpackChunkName: "about" */ '../views/index/index.vue')
      },
      {
        path: 'standard',
        name: 'standard',
        component: () => import(/* webpackChunkName: "about" */ '../views/index/standard.vue')
      },
      {
        path: '/moreReal',
        name: 'moreReal',
        component: () => import(/* webpackChunkName: "about" */ '../views/index/moreReal.vue')
      },
      {
        path: 'newsCenter',
        name: 'newsCenter',
        component: () => import(/* webpackChunkName: "about" */ '../views/index/newsCenter.vue')
      },
      {
        path: 'cooperation',
        name: 'cooperation',
        component: () => import(/* webpackChunkName: "about" */ '../views/index/cooperation.vue')
      },
      {
        path: 'company',
        name: 'company',
        component: () => import(/* webpackChunkName: "about" */ '../views/index/company.vue')
      },
    ]
  },
  {
    path: '/search',
    name: 'search',
    component: () => import(/* webpackChunkName: "about" */ '../views/index/search.vue')
  },
  // {
  //   path: '/moreReal',
  //   name: 'moreReal',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/index/moreReal.vue')
  // },
  {
    path: '/practiceList',
    name: 'practiceList',
    component: () => import(/* webpackChunkName: "about" */ '../views/index/practiceList.vue')
  },
  {
    path: '/my',
    name: 'my',
    component: () => import(/* webpackChunkName: "about" */ '../views/my/index.vue'),
    children: [
      {
        path: 'information',
        name: 'information',
        component: () => import(/* webpackChunkName: "about" */ '../views/my/information.vue')
      },
      {
        path: 'authentication',
        name: 'authentication',
        component: () => import(/* webpackChunkName: "about" */ '../views/my/authentication.vue')
      },
      {
        path: 'wrongQuestion',
        name: 'wrongQuestion',
        component: () => import(/* webpackChunkName: "about" */ '../views/my/wrongQuestion.vue')
      },
      {
        path: 'collectQuestions',
        name: 'collectQuestions',
        component: () => import(/* webpackChunkName: "about" */ '../views/my/collectQuestions.vue')
      },
      {
        path: 'collectJobs',
        name: 'collectJobs',
        component: () => import(/* webpackChunkName: "about" */ '../views/my/collectJobs.vue')
      },
      {
        path: 'studyPoint',
        name: 'studyPoint',
        component: () => import(/* webpackChunkName: "about" */ '../views/my/studyPoint.vue')
      },
      {
        path: 'purchaseHistory',
        name: 'purchaseHistory',
        component: () => import(/* webpackChunkName: "about" */ '../views/my/purchaseHistory.vue')
      },

    ]
  },
  {
    path: '/myArticle',
    name: 'myArticle',
    component: () => import(/* webpackChunkName: "about" */ '../views/my/article.vue')
  },
  {
    path: '/myCourse',
    name: 'myCourse',
    component: () => import(/* webpackChunkName: "about" */ '../views/my/course.vue')
  },
  {
    path: '/jobInfo',
    name: 'jobInfo',
    component: () => import(/* webpackChunkName: "about" */ '../views/job/info.vue')
  },
  {
    path: '/courses',
    name: 'coursesIndex',
    component: () => import(/* webpackChunkName: "about" */ '../views/courses/index.vue')
  },
  {
    path: '/courses/info',
    name: 'coursesInfo',
    component: () => import(/* webpackChunkName: "about" */ '../views/courses/info.vue')
  },
  {
    path: '/examination/selfTest',
    name: 'selfTest',
    component: () => import(/* webpackChunkName: "about" */ '../views/examination/selfTest.vue')
  },
  {
    path: '/examination/practice',
    name: 'practice',
    component: () => import(/* webpackChunkName: "about" */ '../views/examination/practice.vue')
  },
  {
    path: '/examination/imitate',
    name: 'imitate',
    component: () => import(/* webpackChunkName: "about" */ '../views/examination/imitate.vue')
  },
  {
    path: '/examination/contrast',
    name: 'contrast',
    component: () => import(/* webpackChunkName: "about" */ '../views/examination/contrast.vue')
  },
  {
    path: '/news',
    name: 'newsInfo',
    component: () => import(/* webpackChunkName: "about" */ '../views/news/index.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/login/index.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
