import request from '@/utils/request'

const userApi = {
  userInfo: 'user/info',
  image: 'upload/image',
  editUserInfo: 'user/editUserInfo',
  getUserAuthentication: 'user/getUserAuthentication',
  userAuthentication: 'user/userAuthentication',
  wrongQuestionList: 'exam/wrongQuestionList',
  deleteWrongQuestion: 'exam/deleteWrongQuestion',
  collectQuestionsList: 'exam/collectQuestionsList',
  collectList: 'jobPosition/collectList',
  getJobCreditList: 'userJobPositions/getJobCreditList',
  JobCreditLogs: 'userJobPositions/JobCreditLogs'
}

export function JobCreditLogs (parameter) {
  return request({
    url: userApi.JobCreditLogs,
    method: 'get',
    params: parameter
  })
}

export function getJobCreditList (parameter) {
  return request({
    url: userApi.getJobCreditList,
    method: 'get',
    params: parameter
  })
}

export function collectList (parameter) {
  return request({
    url: userApi.collectList,
    method: 'get',
    params: parameter
  })
}

export function collectQuestionsList (parameter) {
  return request({
    url: userApi.collectQuestionsList,
    method: 'get',
    params: parameter
  })
}

export function deleteWrongQuestion (parameter) {
  return request({
    url: userApi.deleteWrongQuestion,
    method: 'post',
    data: parameter
  })
}

export function wrongQuestionList (parameter) {
  return request({
    url: userApi.wrongQuestionList,
    method: 'get',
    params: parameter
  })
}

export function userAuthentication (parameter) {
  return request({
    url: userApi.userAuthentication,
    method: 'post',
    data: parameter
  })
}

export function getUserAuthentication (parameter) {
  return request({
    url: userApi.getUserAuthentication,
    method: 'get',
    params: parameter
  })
}

export function editUserInfo (parameter) {
  return request({
    url: userApi.editUserInfo,
    method: 'post',
    data: parameter
  })
}

export function image (formData) {
  return request({
    url: userApi.image,
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData
  })
}

/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 *     remember_me: true,
 *     captcha: '12345'
 * }
 * @param parameter
 * @returns {*}
 */
export function userInfo (parameter) {
  return request({
    url: userApi.userInfo,
    method: 'get',
    params: parameter
  })
}
