<template>
  <div id="app">
    <pageHeader />
    <div class="page-wrap">
      <router-view />
    </div>
    <footer>
      <div class="footer-box">
        <div class="footer">
          <div class="info">
            <a href="">
              <img src="@/assets/image/Logo-2@2x.png" class="logo" alt="" />
            </a>
          </div>
          <div class="web-info">
            <div class="label-title">网站信息</div>
            <router-link to="/myArticle?id=10001">
              <span class="item-tab" href="./views/my/article?id=10001"
                >隐私政策</span
              >
            </router-link>
            <router-link to="/myArticle?id=10002">
              <span class="item-tab" href="./views/my/article?id=10001"
                >服务协议</span
              >
            </router-link>
            <router-link to="/myArticle?id=10003">
              <div class="about-tab">
                <a class="item-tab" href="">关于我们</a>
              </div>
            </router-link>
          </div>
          <div class="service">
            <p class="label-title">联系我们</p>
            <img src="@/assets/image/code.png" class="item" alt="" />
            <img src="@/assets/image/code1.png" alt="" />
          </div>
          <!-- <div class="download">
            <el-popover placement="top" trigger="hover">
              <img
                src="@/assets/image/code.png"
                class="download-image"
                alt=""
              />
              <span slot="reference">
                <a href="javascript:;">App Store 下载</a>
                <a href="javascript:;">Android 下载</a>
              </span>
            </el-popover>
          </div> -->
        </div>
      </div>
      <div class="footer-text">
        <p class="number">
          粤ICP备2021148888号 粤公网安备44011702000694号
          工业和信息化部备案管理系统网站
        </p>
        <p class="company">
          Copyright © 2023 . All Rights Reserved. 广州华数云计算有限公司
          监督举报邮箱：tech@huashuyun.net
        </p>
      </div>
    </footer>
  </div>
</template>

<script>
import pageHeader from "@/components/pageHeader";
export default {
  components: {
    pageHeader,
  },
};
</script>

<style lang="less">
@import "./assets/css/reset.less";

html,
body {
  height: 100%;
  margin: 0;
  background-color: #ffffff;
  min-width: 1440px;
  color: #030303;
}

#app {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

/*设置宽度,轨道颜色*/
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background: #c6c6c6;
  /*可以设置轨道颜色*/
  padding-right: 2px;
}

/*滚动条*/
::-webkit-scrollbar-thumb {
  background: #e6e6e6;
  border-radius: 10px;
}

.line-clamp-1 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.line-clamp-2 {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.noData {
  padding-top: 75px;
  color: #5e6272;
  text-align: center;
  .emptyimg {
    width: 175px;
  }
  .empty-text {
    text-align: center;
    font-size: 12px;
    line-height: 20px;
    margin-top: 2px;
  }
}
.in-label {
  display: flex;
  align-items: center;
  font-size: 24px;
  font-family: PingFang-SC, PingFang-SC;
  font-weight: 600;
  color: #0b1d28;
  margin-bottom: 40px;
  .icon-tri {
    width: 24px;
    height: 24px;
  }
}
.page-wrap {
  // padding-top: 132px;
}
.page-content {
  width: 1440px;
  margin: 0 auto;
}
footer {
  position: relative;
  z-index: 99;
  margin-top: auto;
  .footer-box {
    height: 278px;
    background: #f1f2f3;
    padding-top: 60px;
    box-sizing: border-box;

    .footer {
      display: flex;
      justify-content: space-between;
      width: 1440px;
      margin: auto;

      .info {
        .logo {
          width: 146px;
        }
      }
      .web-info {
        width: 370px;
        height: 158px;
        background: #eaebeb;
        padding: 20px;
        .item-tab {
          font-size: 13px;
          font-weight: 300;
          color: #494b4e;
          margin-right: 51px;
        }
        .item-tab:hover {
          color: #0059ff;
          font-weight: 600;
        }
        .about-tab {
          margin-top: 10px;
        }
      }
      .label-title {
        font-size: 13px;
        font-weight: 500;
        color: #0b1d28;
        padding-bottom: 22px;
      }
      .service {
        width: 370px;
        height: 158px;
        background: #eaebeb;
        padding: 20px;

        img {
          width: 70px;

          &.item {
            margin-right: 32px;
          }
        }
      }
      .download {
        a {
          width: 256px;
          height: 75px;
          background: #eaebeb;
          border-radius: 4px;
          padding-left: 86px;
          display: flex;
          align-items: center;
          font-size: 18px;
          font-weight: 600;
          color: #0b1d28;
          background-repeat: no-repeat;
          background-size: 40px;
          background-position: left 30px center;
          background-image: url("@/assets/image/Android-icon@2x.png");
          box-sizing: border-box;
          &:first-child {
            background-image: url("@/assets/image/apple-icon@2x.png");
            margin-bottom: 8px;
          }
        }
        a:first-child:hover {
          background-image: url("@/assets/image/apple-icon@2x-active.png");
        }
        a:hover {
          color: #0059ff;
          background-image: url("@/assets/image/Android-icon@2x-active.png");
        }
      }
    }
  }
  .footer-text {
    text-align: center;
    height: 112px;
    background: #eaebeb;
    padding-top: 32px;
    box-sizing: border-box;
    color: #666;
    .number {
      font-size: 14px;
      line-height: 24px;
    }
    .company {
      font-size: 12px;
      line-height: 24px;
    }
  }
}
</style>
