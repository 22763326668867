<template>
  <header>
    <div class="head-logo">
      <div class="head">
        <a href="/">
          <img class="logo" src="@/assets/image/LOGO@2x.png" alt="" />
        </a>
        <!-- <div class="search" to="/search">
        <input type="text" v-model.trim="keyword" placeholder="课程、新闻、人才标准、真题等">
        <a href="javascript:;" class="search-btn" @click="search">搜索</a>
      </div> -->
        <div class="user-right">
          <el-popover placement="bottom-end" trigger="hover">
            <img src="@/assets/image/mini-caode.png" class="download-image" alt="" />
            <a href="javascript:;" slot="reference" class="download">人才评定小程序</a>
          </el-popover>
          <!-- <a href="javascript:;" @click="myCourse" class="my-course">我的课程</a> -->

          <el-popover placement="bottom-end" v-if="token" trigger="hover">
            <div class="user-pop">
              <div class="user-info">
                <img
                  :src="
                    userInfo.avatar_url ||
                    require('@/assets/image/avatar@2x.png')
                  "
                  class="avatar-image"
                  alt=""
                />
                <div class="user">
                  <p class="name line-clamp-1">
                    {{ userInfo.real_name || userInfo.nick_name || "学员" }}
                  </p>
                  <p class="mobile">{{ userInfo.new_mobile }}</p>
                </div>
              </div>
              <div class="ul">
                <router-link to="/my/information" class="li">
                  <span class="label">个人中心</span>
                </router-link>
                <router-link to="/my/studyPoint" class="li">
                  <span class="label">我的学分</span>
                </router-link>
                <router-link to="/my/information" class="li">
                  <span class="label">实名认证</span>
                  <span class="value">
                    {{ userInfo.is_license ? "已实名" : "去实名" }}
                    <i class="el-icon-arrow-right"></i>
                  </span>
                </router-link>
                <!-- <router-link to="/my/wrongQuestion" class="li">
                  <span class="label">错题本</span>
                </router-link>
                <router-link to="/my/collectQuestions" class="li">
                  <span class="label">我的收藏</span>
                </router-link> -->
              </div>
              <div class="ul">
                <a href="javascript:;" @click="connectShow = true" class="li">
                  <span class="label">联系客服</span>
                </a>
              </div>
              <div class="ul">
                <!-- <router-link to="/my/article?id=10001" class="li">
                  <span class="label">隐私政策</span>
                </router-link> -->
                <!-- <router-link to="/my/article?id=10002" class="li">
                  <span class="label">用户服务协议</span>
                </router-link> -->
                <!-- <router-link to="/my/article?id=10003" class="li">
                  <span class="label">关于</span>
                </router-link> -->
                <a href="javascript:;" @click="logOut" class="li">
                  <span class="label">退出登录</span>
                </a>
              </div>
            </div>

            <a href="javascript:;" slot="reference" class="login">
              <span class="line-clamp-1">{{
                userInfo.real_name || userInfo.nick_name || "学员"
              }}</span>
              <div class="avatar">
                <img :src="getAvatar" alt="" />
              </div>
            </a>
          </el-popover>

          <router-link to="/login" class="login" v-if="!token">
            <span>登录/注册</span>
            <!-- <div class="avatar"></div> -->
          </router-link>
        </div>

        <el-dialog
          title="联系客服"
          width="350px"
          :modal-append-to-body="false"
          :visible.sync="connectShow"
        >
          <div class="connect-pop">
            <p class="name">华数云AI</p>
            <img :src="connectUrl" alt="" />
            <p class="tips">微信扫描二维码，添加客服！</p>
          </div>
        </el-dialog>
      </div>
      <!-- <div class="haed-nav">
        <router-link to="/" class="nav">
          <span>首页</span>
        </router-link>
        <router-link to="/standard" class="nav">
          <span>人才标准</span>
        </router-link>
        <router-link to="/moreReal?job_id=1" class="nav">
          <span>学习中心</span>
        </router-link>
        <router-link to="/newsCenter" class="nav">
          <span>新闻资讯</span>
        </router-link>
        <router-link to="/learningCenter" class="nav">
          <span>商业合作</span>
        </router-link>
        <router-link to="/learningCenter" class="nav">
          <span>公司介绍</span>
        </router-link> 
      </div> -->
    </div>
  </header>
</template>

<script>
import { mapState } from "vuex";
import { userInfo } from "@/api/user";
import { shareConfig } from "@/api/job";

export default {
  name: "pageHeader",
  computed: {
    ...mapState({
      token: (state) => state.user.token,
    }),

    getAvatar() {
      const { avatar_url } = this.userInfo;
      if (avatar_url) {
        return avatar_url;
      }
      return require("@/assets/image/avatar@2x.png");
    },
  },

  data() {
    return {
      keyword: "",
      userInfo: {
        avatar_url: "",
      },
      connectUrl: "",
      connectShow: false,
      tabs: [
        "首页",
        "人才标准",
        "学习中心",
        "新闻资讯",
        "商业合作",
        "公司介绍",
      ],
      count: 0,
      typeName: "",
      logoImg: require("@/assets/image/LOGO@2x.png"),
      logoImg1: require("@/assets/image/LOGO@2x-ac.png"),
    };
  },

  props: {},

  mounted() {
    setTimeout(() => {
      this.keyword = this.$route.query.keyword;
    }, 500);
    if (this.token) {
      this.getUser();
    }
    this.getShareConfig();
    this.typeName = this.$route.name;
  },

  watch: {
    token(value) {
      if (value) {
        this.getUser();
      }
    },
    $route(to, from) {
      console.log(to.name);
      this.typeName = to.name;
    },
  },

  methods: {
    handelActive(index) {
      this.count = index;
    },
    getUser() {
      userInfo().then((result) => {
        this.userInfo = result.data.userInfo;
      });
    },

    getShareConfig() {
      shareConfig().then((result) => {
        this.connectUrl = result.data.share_agent_url;
      });
    },

    search() {
      const { keyword } = this;
      if (keyword) {
        if (this.$route.path === "/search") {
          this.$router.replace(
            { path: "/search", query: { keyword } },
            () => {}
          );
        } else {
          this.$router.push({ path: "/search", query: { keyword } });
        }
      } else {
        this.$message.error("请输入搜索内容");
      }
    },

    myCourse() {
      const { token } = this;
      if (this.$route.path === "/login") return;
      if (token) {
        // const routeUrl = this.$router.resolve({ path: '/myCourse' })
        // window.open(routeUrl.href, '_blank')
        this.$router.push({ path: "/myCourse" });
      } else {
        // 未登录
        this.$router.push({ path: "/login" });
      }
    },

    logOut() {
      this.$confirm("是否确认退出登录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        confirmButtonClass: "exitConfirm",
      })
        .then(() => {
          this.$store.dispatch("LogOut", {}).then(() => {
            this.$message.success("退出成功");
            this.$router.push({ path: "/login" });
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.exitConfirm {
  /deep/ * {
    background: #0059ff !important;
    color: #999;
  }
}
.download-image {
  width: 150px;
}

.user-pop {
  width: 150px;

  .user-info {
    display: flex;
    align-items: center;
    padding-bottom: 16px;

    .avatar-image {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      object-fit: cover;
      background-image: url("@/assets/image/avatar@2x.png");
      background-size: 100%;
      flex-shrink: 0;
    }

    .user {
      margin-left: 10px;
      flex: 1;
      overflow: hidden;

      .name {
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        color: #4a4a4a;
      }

      .mobile {
        color: #a6a5b3;
        font-size: 10px;
        line-height: 10px;
      }
    }
  }

  .ul {
    padding: 10px 0;
    border-top: 1px solid #f1f1f1;

    .li {
      display: flex;
      align-items: center;
      color: #4a4a4a;
      font-size: 13px;
      line-height: 20px;
      font-weight: 500;
      margin-top: 16px;
      background-color: #fff;

      &:first-child {
        margin: 0;
      }

      .value {
        margin-left: auto;
        color: #a6a5b3;
        font-size: 10px;
        font-weight: 400;
      }
    }
  }
}

header {
  .head-logo {
    width: 100%;
    height: 68px;
    background: #0059ff;
    // box-shadow: inset 0px -1px 0px 0px rgba(255, 255, 255, 0.2);
    // position: fixed;
    z-index: 100;
    left: 0;
    right: 0;
    top: 0;
    font-size: 14px;
    color: #fff;
    // padding-top: 25px;
    display: flex;
    align-items: center;
    padding-top: 10px;
  }

  .head {
    width: 1440px;
    margin: auto;
    display: flex;
    align-items: center;
    height: 52px;

    .logo {
      width: 460px;
      height: 40px;
    }
  }
  .haed-nav {
    display: flex;
    width: 1440px;
    margin: 20px auto 0 auto;

    .nav {
      width: 240px;
      height: 54px;
      line-height: 54px;
      font-size: 20px;
      color: #ffffff;
      // margin-right: 48px;
      text-align: center;
      // padding-bottom: 5px;
      font-weight: 500;
      cursor: pointer;
    }
    .nav:hover {
      background: #0049cb;
      font-weight: 600;
    }
    .active {
      border-bottom: 4px solid #fff;
    }
  }

  .search {
    display: flex;
    margin-left: 24px;

    input {
      border-radius: 5px 0 0 5px;
      border: 1px solid #fe2c2c;
      height: 36px;
      width: 454px;
      font-size: 13px;
      padding-left: 10px;
      box-sizing: border-box;
    }

    .search-btn {
      width: 56px;
      height: 36px;
      background: #fe2c2c;
      border-radius: 0px 5px 5px 0px;
      color: #ffffff;
      font-size: 13px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .user-right {
    margin-left: auto;
    display: flex;
    align-items: center;
    a {
      color: #fff;
    }
  }

  .download {
    font-weight: 500;
    padding: 0 20px;
    border-right: 1px solid #fff;
  }

  .my-course {
    font-weight: 500;
    margin-left: 20px;
    padding: 0 20px;
    border-left: 1px solid #4a4a4a;
    border-right: 1px solid #4a4a4a;
  }

  .login {
    padding-left: 20px;
    display: flex;
    align-items: center;
    font-weight: 500;
    color: #fff;
    background: none;

    .line-clamp-1 {
      max-width: 200px;
    }

    .avatar {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-image: url("@/assets/image/avatar@2x.png");
      background-size: 100%;
      margin-left: 10px;

      img {
        width: 30px;
        height: 30px;
        object-fit: cover;
        border-radius: 50%;
      }
    }
  }

  .connect-pop {
    text-align: center;
    .name {
      font-weight: 600;
      font-size: 18px;
    }
    img {
      width: 250px;
      margin: 25px 0;
    }
    .tips {
      font-size: 14px;
      color: #666666;
    }
  }
}

.router-link-exact-active {
  // border-bottom: 4px solid #fff;
  background: #0049cb;
}
</style>
