import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { Carousel, CarouselItem, Message, Popover, MessageBox, Dialog } from 'element-ui'
import './assets/theme/index.css'
import storage from '@/utils/storage'// 引入

import Directive from './directives' // 自定义指令
Vue.use(Directive)

Vue.prototype.$storage = storage// 加入vue的原型对象

Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Popover)
Vue.use(Dialog)

Vue.prototype.$message = Message
Vue.prototype.$confirm = MessageBox.confirm

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
